import React from 'react'

const Pagination = ({pagination, changePage, updatePerPage}) => {

    const previousPage = () => {
        if(pagination.currentPage > 1) {
            changePage(pagination.currentPage - 1)
        }
    }

    const nextPage = () => {
        let pages = Math.ceil(pagination.totalItems / pagination.perPage)
        if(pagination.currentPage < pages) {
            changePage(pagination.currentPage + 1)
        }
    }

    const changePerPage = (input) => {
        let pages = Math.ceil(pagination.totalItems / pagination.perPage)
        console.log(pages)
        updatePerPage(input)
    }

    const changeCurrentPage = (input) => {
        let pages = Math.ceil(pagination.totalItems / pagination.perPage)
        if(!input || input === 0 || input > pages) {
            return
        }
        changePage(input)
    }

    const lastPage = () => {
        changePage(Math.ceil(pagination.totalItems / pagination.perPage))
    }
    
    const firstPage = () => {
        changePage(1)
    }

    const perPageOptions = [
        25, 50, 75, 100
    ]

    return (
        <div className='w-full flex flex-row items-center justify-between py-5 mt-5'>
            <div className='flex flex-row items-center gap-x-2'>
                <button onClick={()=>{firstPage()}} className='rounded bg-secondary text-accent py-2 px-4 transition duration-200 hover:bg-opacity-30'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                    </svg>
                </button>
                <button onClick={()=>{previousPage()}} className='rounded bg-secondary text-accent py-2 px-4 transition duration-200 hover:bg-opacity-30'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                </button>
            </div>

            <div className='flex flex-row gap-x-2 items-center'>
                <p className='text-accent text-xs'>Page</p>
                <input type='number' className='text-xs p-2 border rounded border-secondary w-12 focus:border-blue-700 bg-transparent text-gray-500 focus:outline-none' onChange={(e)=>{changeCurrentPage(e.target.value)}} value={pagination.currentPage || ''} /> 
                <p className='text-accent text-xs'>of {Math.ceil(pagination.totalItems / pagination.perPage)}</p>
            </div>
            
            <div className='flex flex-row items-center gap-x-2'>
                <div className='flex flex-row gap-x-2 items-center mr-3'>
                    <p className='text-accent text-xs'>Items per page:</p>
                    <select value={pagination.perPage || ''} onChange={(e)=>{changePerPage(e.target.value)}} className='text-xs p-2 border rounded border-secondary w-20 focus:border-blue-700 bg-transparent text-gray-500 focus:outline-none'>
                        {perPageOptions.map((option, optionIndex)=>(
                            <option key={optionIndex} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
                <button onClick={()=>{nextPage()}} className='rounded bg-secondary text-accent py-2 px-4 transition duration-200 hover:bg-opacity-30'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                </button>
                <button onClick={()=>{lastPage()}} className='rounded bg-secondary text-accent py-2 px-4 transition duration-200 hover:bg-opacity-30'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                    </svg>
                </button>
            </div>
        </div>
    )
}

export default Pagination