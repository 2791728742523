import React from 'react'
import UserLayout from '../../../components/layout/UserLayout'

const EmployeeDetails = () => {
  return (
    <UserLayout pageTitle={`Employee Details`}>
      
    </UserLayout>
  )
}

export default EmployeeDetails