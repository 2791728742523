import React, { useEffect, useState } from 'react'
// import DataTable from '../../../components/elements/DataTable'
import PlusIcon from '../../../components/elements/icons/PlusIcon'
// import ListIcon from '../../../components/elements/icons/ListIcon'
import UserLayout from '../../../components/layout/UserLayout'
import { debounce, unSlugify } from '../../../utils'
// import TaskExpansion from '../../../components/partials/tasks/TaskExpansion'
import Filters from '../../../components/elements/Filters'
// import CustomerSnippet from '../../../components/elements/CustomerSnippet'
// import OrderStatus from '../../../components/elements/OrderStatus'
// import GridIcon from '../../../components/elements/GridIcon'
import TaskCard from '../../../components/partials/tasks/TaskCard'
import EmptyState from '../../../components/elements/icons/EmptyState'
import ModalLayout from '../../../components/layout/ModalLayout'
import NewTask from '../../../components/partials/tasks/NewTask'
import { useDispatch, useSelector } from 'react-redux'
import { clearCreatedTask, fetchTasks, updateTask } from '../../../store/actions/tasksActions'
import { SET_SUCCESS_MESSAGE } from '../../../store/types'
import TaskDetails from '../../../components/partials/tasks/TaskDetails'

const Tasks = () => {
    const tasksSelector = useSelector(state => state.tasks)
    const dispatch = useDispatch()
    // const taskList = [
    //     {
    //         id: "13653",
    //         title: "Pityful a rethoric question ran over",
    //         description: "CWhen, while the lovely valley teems with vapour around me, and the meridian sun strikes the upper surface of the impenetrable foliage of my trees.",
    //         assignee: "Finance Department",
    //         project: "34KM Road Construction at Gboko",
    //         priority: 'high',
    //         subtasks: [
    //             {
    //                 name: "A collection of textile samples",
    //                 description:"A small river named Duden flows by their place and supplies it with the necessary regelialia",
    //                 done: false
    //             },
    //             {
    //                 name: "A small river named Duden",
    //                 description:"Alphabet Village and the subline of her own road",
    //                 done: false

    //             },
    //             {
    //                 name: "Pityful a rethoric question ran over.",
    //                 description:"Separated they live in Bookmarksgrove right at the coast of the Semantics",
    //                 done: false

    //             },
    //             {
    //                 name: "A wonderful serenity.",
    //                 description:" Pityful a rethoric question ran over her cheek",
    //                 done: false

    //             },
    //         ],
    //         dueDate: "21/08/2023",
    //         status: "DONE", // IN_REVIEW, DONE, BACKLOG, IN_PROGRESS
    //         pastDue: false,
    //         dateCreated: "21/08/2023"
    //     },
    //     {
    //         id: "13653",
    //         title: "A wonderful serenity.",
    //         description: "When, while the lovely valley teems with vapour around me, and the meridian sun strikes the upper surface of the impenetrable foliage of my trees",
    //         assignee: "HOGL",
    //         project: "Oil Rig Construction off the coast of the Indian Ocean",
    //         priority: 'high',
    //         subtasks: [
    //             {
    //                 name: "A collection of textile samples",
    //                 description:"A small river named Duden flows by their place and supplies it with the necessary regelialia",
    //                 done: false
    //             },
    //             {
    //                 name: "A small river named Duden",
    //                 description:"Alphabet Village and the subline of her own road",
    //                 done: false

    //             },
    //             {
    //                 name: "Pityful a rethoric question ran over.",
    //                 description:"Separated they live in Bookmarksgrove right at the coast of the Semantics",
    //                 done: false

    //             },
    //             {
    //                 name: "A wonderful serenity.",
    //                 description:" Pityful a rethoric question ran over her cheek",
    //                 done: false

    //             },
    //         ],
    //         dueDate: "21/08/2023",
    //         status: "IN_REVIEW", // IN_REVIEW, DONE, BACKLOG, IN_PROGRESS
    //         pastDue: false,
    //         dateCreated: "21/08/2023"
    //     },
    //     {
    //         id: "13653",
    //         title: "A small river named Duden",
    //         description: "When, while the lovely valley teems with vapour around me, and the meridian sun strikes the upper surface of the impenetrable foliage of my trees",
    //         assignee: "Hudson Petroleum",
    //         project: "Gas bottling in Port Harcourt",
    //         priority: 'normal',
    //         subtasks: [
    //             {
    //                 name: "A collection of textile samples",
    //                 description:"A small river named Duden flows by their place and supplies it with the necessary regelialia",
    //                 done: false
    //             },
    //             {
    //                 name: "A small river named Duden",
    //                 description:"Alphabet Village and the subline of her own road",
    //                 done: false

    //             },
    //             {
    //                 name: "Pityful a rethoric question ran over.",
    //                 description:"Separated they live in Bookmarksgrove right at the coast of the Semantics",
    //                 done: false

    //             },
    //             {
    //                 name: "A wonderful serenity.",
    //                 description:" Pityful a rethoric question ran over her cheek",
    //                 done: false

    //             },
    //         ],
    //         dueDate: "21/08/2023",
    //         status: "IN_PROGRESS", // IN_REVIEW, DONE, BACKLOG, IN_PROGRESS
    //         pastDue: false,
    //         dateCreated: "21/08/2023"
    //     },
    //     {
    //         id: "13653",
    //         title: "A collection of textile samples",
    //         description: "When, while the lovely valley teems with vapour around me, and the meridian sun strikes the upper surface of the impenetrable foliage of my trees",
    //         assignee: "Human Resources & Admin",
    //         project: "Staffing for NNPCL",
    //         priority: 'low',
    //         subtasks: [
    //             {
    //                 name: "A collection of textile samples",
    //                 description:"A small river named Duden flows by their place and supplies it with the necessary regelialia",
    //                 done: false
    //             },
    //             {
    //                 name: "A small river named Duden",
    //                 description:"Alphabet Village and the subline of her own road",
    //                 done: false

    //             },
    //             {
    //                 name: "Pityful a rethoric question ran over.",
    //                 description:"Separated they live in Bookmarksgrove right at the coast of the Semantics",
    //                 done: false

    //             },
    //             {
    //                 name: "A wonderful serenity.",
    //                 description:" Pityful a rethoric question ran over her cheek",
    //                 done: false

    //             },
    //         ],
    //         dueDate: "21/08/2023",
    //         status: "IN_PROGRESS", // IN_REVIEW, DONE, BACKLOG, IN_PROGRESS
    //         pastDue: false,
    //         dateCreated: "21/08/2023"
    //     },
    //     {
    //         id: "13653",
    //         title: "It showed a lady fitted out with a fur",
    //         description: "When, while the lovely valley teems with vapour around me, and the meridian sun strikes the upper surface of the impenetrable foliage of my trees",
    //         assignee: "Shoreline Logistics",
    //         project: "10,000Ltr diesel haulage to Lagos",
    //         priority: 'high',
    //         subtasks: [
    //             {
    //                 name: "Vehicula ipsum a arcu cursus vitae congue",
    //                 description:"Mattis enim ut tellus elementum sagittis vitae et leo. Neque volutpat ac tincidunt vitae semper",
    //                 done: false
    //             },
    //             {
    //                 name: "Turpis nunc eget lorem dolor sed",
    //                 description:"Cursus euismod quis viverra nibh cras pulvinar mattis nunc sed",
    //                 done: false

    //             },
    //             {
    //                 name: "Euismod elementum nisi quis eleifend quam.",
    //                 description:"Interdum velit euismod in pellentesque massa placerat duis. Id aliquet risus feugiat in ante metus dictum at.",
    //                 done: false

    //             },
    //             {
    //                 name: "Diam volutpat commodo sed egestas.",
    //                 description:"Cras ornare arcu dui vivamus arcu felis bibendum.",
    //                 done: false

    //             },
    //         ],
    //         dueDate: "21/08/2023",
    //         status: "BACKLOG", // IN_REVIEW, DONE, BACKLOG, IN_PROGRESS
    //         pastDue: false,
    //         dateCreated: "21/08/2023"
    //     },
    // ]

    useEffect(() => {
        dispatch(fetchTasks())
        
        if(tasksSelector.createdTask !== null){
            dispatch(clearCreatedTask())
            dispatch({
                type: SET_SUCCESS_MESSAGE,
                payload: {
					successMessage: "New task created successfully"
                }
            })
            setAddingTask(false)
        }
        // if(tasksSelector.updatedTask !== null){
        //     dispatch(clearUpdatedTask())
            // dispatch({
            //     type: SET_SUCCESS_MESSAGE,
            //     payload: {
			// 		successMessage: "New task created successfully"
            //     }
            // })
            // setAddingTask(false)
        // }
        return () => {
            
        };
    }, [
        dispatch, 
        tasksSelector.createdTask, 
        // tasksSelector.updatedTask
    ]);
    
    const filters = [
        // {
        //     field: 'Custodian',
        //     value: 'custodian',
        //     operators: [{
        //         name: 'is',
        //         value: 'is',
        //         selected: true
        //     }],
        //     termOptions: members,
        //     type: 'select',
        //     selectedTerm: ''
        // },
        {
            field: 'Status',
            value: 'currency',
            operators: [{
                name: 'is equal to',
                value: 'is',
                selected: true
            }],
            termOptions: ['BACKLOG', 'IN_PROGRESS', 'IN_REVIEW', 'DONE'],
            type: 'select',
            selectedTerm: ''
        },
        {
            field: 'Status',
            value: 'status',
            operators: [{
                name: 'is equal to',
                value: 'is',
                selected: true
            }],
            termOptions: ['FROZEN', 'RETIRED', 'ACTIVE'],
            type: 'select',
            selectedTerm: ''
        },
        {
            field: 'Assignee',
            value: 'assignee',
            operators: [{
                name: 'is equal to',
                value: 'is',
                selected: true
            }],
            type: 'search',
            termOptions: [],
            selectedTerm: ''
        },
        {
            field: 'Date Created',
            value: 'createdAt',
            operators: [
                {
                    name: 'is before',
                    value: 'gt',
                    selected: true
                },
                {
                    name: 'is after',
                    value: 'lt',
                    selected: false
                },
            ],
            type: 'date',
            termOptions: [],
            selectedTerm: ''
        },
    ]
    
    // const columnWidths = {
    //     id: "w-full lg:w-1/12",
    //     assignee: "w-full lg:w-3/12",
    //     project: "w-full lg:w-2/12",
    //     subtasks: "w-full lg:w-1/12",
    //     status: "w-full lg:w-1/12",
    //     dateCreated: "w-full lg:w-2/12",
    //     dueDate: "w-full lg:w-2/12",
    // }
    
    // const cleanupData = (dataSet) => {
    //     const data = []
    
    //     dataSet.forEach((item, itemIndex) => {
    //     data.push(
    //         {
    //             id: item.id,
    //             assignee: <CustomerSnippet showIcon={true} customerName={item.assignee} customerPhone="Headed by James Cameron" />,
    //             project: item.project,
    //             subtasks: `${item.subtasks.length} subtasks`,
    //             status: <OrderStatus status={item.status} />,
    //             dateCreated: item.dateCreated,
    //             dueDate: item.dueDate,
    //         },
    //     )
    //     })
    
    //     return data
    // }
    
    // const [rowOpen, setRowOpen] = useState(null)
    
    // const toggleRowOpen = (rowIndex) => {
    //     if(rowOpen === null) {
    //         setRowOpen(rowIndex)
    //     } else {
    //         setRowOpen(null)
    //     }
    // } 
    
    // const tableOptions = {
    //     selectable: false,
    //     expandable: true,
    //     clickableRows: true,
    //     rowAction: (value)=>{toggleRowOpen(value)}
    // }
    
    const initialFilters = [
        {
          field: 'description',
          operator: 'like',
          term: ''
        },
        {
          field: 'name',
          operator: 'like',
          term: ''
        },
    ]
    
    const [searchFilter, setSearchFilter] = useState(initialFilters)
    
    const captureFilter = (filter) => {
        let tempFilters = [...searchFilter] 
        tempFilters.push(filter)     
        setSearchFilter(tempFilters)
    }

    const [listView] = useState(false)
    const [taskOpen, setTaskOpen] = useState(false);
    const [activeOpenTask, setActiveOpenTask] = useState(null);

    const [addingTask, setAddingTask] = useState(false);

    const openTask = (task) => {
        setActiveOpenTask(task)
        setTimeout(() => {
            setTaskOpen(true)
        }, 50);
    }

    const closeTask = () => {
        setTaskOpen(false)
        setTimeout(() => {
            setActiveOpenTask(null)
        }, 50);
    }

    const columns = [
        {
            title: "backlog"
        },
        {
            title: "todo"
        },
        {
            title: "in_progress"
        },
        {
            title: "blocked"
        },
        {
            title: "cancelled"
        },
        {
            title: "done"
        },
    ]

    const startDrag = (e) => {
        console.log('dragging...')
        e.dataTransfer.setData('text/plain', e.target.id);
        setTimeout(() => {
            e.target.classList.add('hidden');
            e.target.classList.add('cursor-grabbing');
        }, 0);
    }

    const dragEnter = (e) => {
        console.log('dragging into...')
        e.preventDefault();
        setTimeout(() => {
            // e.target.classList.add('border-dashed');
            e.target.classList.add('border-purple-400');
        }, 0);
    }

    const dragOver = (e) => {
        console.log('dragging over...')
        e.preventDefault();
        setTimeout(() => {
            // e.target.classList.add('border-dashed');
            e.target.classList.add('border-purple-400');
        }, 0);
    }

    const dragLeave = (e) => {
        console.log('dragging out of...')
        e.preventDefault();
        setTimeout(() => {
            // e.target.classList.remove('border-dashed');
            e.target.classList.remove('border-purple-400');
        }, 0);
    }

    const drop = (e, status) => {
        e.preventDefault();
        
        // Remove any border class
        e.target.classList.remove('border-purple-400');
    
        // Get the draggable element's id
        const id = e.dataTransfer.getData('text/plain');
        const draggable = document.getElementById(id);
    
        // Ensure the draggable exists and is not already in the target
        if (!draggable || e.target === draggable || draggable.parentNode === e.target) {
            console.error('Draggable element not found or already in the target');
            return;
        }
    
        // Ensure that the drop target is the intended container
        if (!e.target.classList.contains('drop-zone')) {
            console.error('Not a valid drop target');
            return;
        }
    
        // Append the draggable to the drop target
        e.target.appendChild(draggable);
    
        // Remove hidden class to display it again
        draggable.classList.remove('hidden');
    
        // Update the task status
        // setTimeout(() => {
        //     dispatch(updateTask(id, { status }));
        // }, 200);

        debounce(dispatch(updateTask(id, { status })))
    };
    
    

    const [activeStatus, setActiveStatus] = useState('');
    const openNewTask = (status) => {
        setActiveStatus(status)
        setTimeout(() => {
            setAddingTask(true)
        }, 50);
    }

    const closeNewTask = () => {
        setAddingTask(false)
        setTimeout(() => {
            setActiveStatus('')
        }, 50);
    }

    return (
        <>
            <UserLayout pageTitle={`Task Management`}>
                <div className=''>
                    <div className='w-full lg:flex flex-row gap-x-8'>
                        <div className='w-full  mx-auto pb-6 px-0'>
                            <div className='flex justify-between items-center mt-4 mb-4'>
                                <h3 className='font-medium text-lg text-gray-400'>Tasks</h3>
                                {/* <div className='flex items-center'>
                                    <button onClick={()=>{setListView(true)}} className={`border p-2 border-gray-800 flex items-center gap-x-2 text-sm rounded-l ${listView ? 'bg-gray-800 text-verovian-light-purple' : 'bg-transparent text-gray-800'}`}>
                                        <ListIcon className={`w-5 h-5`} />
                                    </button>
                                    <button onClick={()=>{setListView(false)}} className={`border border-l-0 p-2 border-gray-800 flex items-center gap-x-2 text-sm rounded-r ${!listView ? 'bg-gray-800 text-verovian-light-purple' : 'bg-transparent text-gray-800'}`}>
                                        <GridIcon className={`w-5 h-5`} />
                                    </button>
                                </div> */}
                            </div>
                            {listView ? 
                                <>
                                    <div className='w-full pt-5 flex items-center justify-between'>
                                        <div className='w-10/12'>
                                            <Filters filterLabel="Filter Tasks" filterOptions={filters} returnSelected={(filter)=>{captureFilter(filter)}} resetFilters={()=>{setSearchFilter(initialFilters)}} />
                                        </div>
                                        <div className='2/12'>
                                            <button onClick={()=>{openNewTask('')}} className='flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
                                                <PlusIcon className={`h-5 w-5`} />
                                                Create a new task
                                            </button>
                                        </div>
                                    </div>

                                    <div className='w-full'>

                                        {tasksSelector.tasks.tasks.length === 0 ? 
                                            <EmptyState emptyStateText={`No tasks yet. Use the 'create a new task' button to create a new task`} />
                                        :
                                            {/* <DataTable
                                                tableHeaders={tableHeadersFields(cleanupData(tasks)[0]).headers} 
                                                tableData={cleanupData(tasks)} 
                                                columnWidths={columnWidths}
                                                columnDataStyles={{}}
                                                allFields={tableHeadersFields(cleanupData(tasks)[0]).fields}
                                                onSelectItems={()=>{}}
                                                tableOptions={tableOptions}
                                                pagination={{
                                                    perPage: 25, 
                                                    currentPage: 1,
                                                    totalItems: 476,
                                                }}
                                                changePage={()=>{}}
                                                updatePerPage={()=>{}}
                                                expandedIndex={rowOpen || ''}
                                                expansion={<TaskExpansion 
                                                    tasks={tasks} 
                                                    rowOpen={rowOpen} 
                                                />}
                                            /> */}
                                        }
                                    </div>
                                </>
                                :
                                <div className=' w-full flex items-start justify-between gap-x-6 overflow-x-scroll'>
                                    {columns.map((column, columnIndex)=>(
                                        <div key={columnIndex} 
                                            className='drop-zone min-h-[80vh] h-inherit border bg-verovian-light-purple bg-opacity-30 min-w-[400px] border-gray-500 p-4 rounded-[8px] border-dashed' onDragEnter={(e)=>{dragEnter(e)}} 
                                            onDragOver={(e)=>{dragOver(e)}}
                                            onDragLeave={(e)=>{dragLeave(e)}}
                                            onDrop={(e)=>{drop(e, column.title)}}
                                            id={`column-${columnIndex}`}
                                        >
                                        <div className='flex items-center justify-between'>
                                            <h3 className='w-full uppercase font-medium tracking-[0.2em] text-verovian-purple text-[13px]'>{(unSlugify(column.title))}</h3>
                                            <button onClick={()=>{openNewTask(column.title)}} className='rounded-[8px] w-[40px] h-[40px] border border-dashed bg-white shadow-md active:shadow-none border-gray-300 text-xs text-gray-600 text-center hover:border-gray-600 hover:text-gray-600 transition duration-200'>
                                                <span className='flex items-center justify-center gap-x-[5px] uppercase tracking-[0.2em]'>
                                                    <PlusIcon className={`w-5 h-5`} />
                                                    {/* Create a new Task */}
                                                </span>
                                            </button>
                                        </div>

                                        {/* {tasksSelector?.tasks?.tasks?.filter((task)=>{return task.status === column.title}).length === 0 && <div className='p-[15px] mt-[10px] bg-white bg-opacity-70 rounded border border-gray-200 border-dashed w-[360px]'>
                                            <p className='text-xs text-gray-600 font-medium'>No tasks yet</p>
                                            <p className='text-xs text-gray-500 mt-[5px]'>Use the 'create a new task' button to create a new task with the status <span className='font-medium'>{unSlugify(column.title.toLowerCase())}</span></p>
                                        </div>} */}

                                        {tasksSelector?.tasks?.tasks?.filter((task)=>{return task.status === column.title}).map((task, taskIndex)=>(
                                            <div id={`${task._id}`} key={taskIndex} className='my-5 cursor-grab' draggable="true" onDragStart={(e)=>{startDrag(e)}}>
                                                <TaskCard task={task} openTask={()=>{openTask(task)}} />
                                            </div>
                                        ))}
                                        
                                    </div>))}
                                </div>
                            }

                        </div>
                    </div>

                </div>
            </UserLayout>

            {activeOpenTask && <ModalLayout
                isOpen={taskOpen} 
                closeModal={()=>{closeTask()}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='Admit Guest' 
                dialogTitle='Task Details'
                maxWidthClass='max-w-2xl'
            >
                <TaskDetails task={activeOpenTask} />
            </ModalLayout>}

            <ModalLayout
                isOpen={addingTask} 
                closeModal={()=>{closeNewTask()}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='Create task' 
                dialogTitle='Create a new task'
                maxWidthClass='max-w-2xl'
            >
                <NewTask
                    taskStatus={activeStatus}
                    parentTask={''}
                />
            </ModalLayout>
        </>
    )
}


export default Tasks