import React from 'react'
import UserLayout from './UserLayout';
import { Link, useLocation } from 'react-router-dom';

const ReviewsManagementLayout = ({children, sectionTitle}) => {
    const location = useLocation();
    const currentRoute = location.pathname;
    return (
        <UserLayout pageTitle="ReviewsManagement">

            <div className='w-full flex items-start gap-x-8'>
                <div className='min-w-[200px] rounded'>
                    <Link to={`/user/reviews-management/reviews`}>
                        <button className={`w-full flex items-center gap-x-3 px-2 py-1 mb-1`}>
                            <p className={`text-sm ${(currentRoute.includes('/reviews-management/reviews')) && 'font-bold text-verovian-purple'}`}>Reviews</p>
                        </button>
                    </Link>
                    <Link to={`/user/reviews-management/review-schedules`}>
                        <button className='w-full flex items-center gap-x-3 px-2 py-1 mb-1'>
                            <p className={`text-sm ${currentRoute.includes('/reviews-management/review-schedules') && 'font-bold text-verovian-purple'}`}>Review Schedules</p>
                        </button>
                    </Link>
                    <Link to={`/user/reviews-management/review-forms`}>
                        <button className='w-full flex items-center gap-x-3 px-2 py-1 mb-1'>
                            <p className={`text-sm ${currentRoute.includes('/reviews-management/review-forms') && 'font-bold text-verovian-purple'}`}>Review Forms</p>
                        </button>
                    </Link>
                    <Link to={`/user/reviews-management/review-questions`}>
                        <button className='w-full flex items-center gap-x-3 px-2 py-1 mb-1'>
                            <p className={`text-sm ${currentRoute.includes('/reviews-management/review-questions') && 'font-bold text-verovian-purple'}`}>Review questions</p>
                        </button>
                    </Link>
                </div>

                <div className='w-full max-w-[100%]'>
                    <h3 className='uppercase font-light text-[15px] tracking-[0.2em]'>{sectionTitle}</h3>
                    <main>{children}</main>
                </div>
            </div>
        </UserLayout>
    )
}

export default ReviewsManagementLayout