import React, { useEffect, useState } from 'react'
import ReviewsManagementLayout from '../../../../components/layout/ReviewsManagementLayout'
import { useDispatch, useSelector } from 'react-redux'
import { getReviews } from '../../../../store/actions/reviewsActions'
import Preloader from '../../../../components/elements/Preloader'
import EmptyState from '../../../../components/elements/icons/EmptyState'
import Pagination from '../../../../components/elements/Pagination'

const Reviews = () => {
  const reviewsSelector = useSelector(state => state.reviews)
  const dispatch = useDispatch()
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);

  useEffect(() => {
      dispatch(getReviews(page, perPage))

      return () => {
          
      };
  }, [dispatch, page, perPage, reviewsSelector.deletedReviewForm]);

  
  return (
    <ReviewsManagementLayout sectionTitle={`Reviews`}>
         <div className=''>
        <div className='w-full lg:flex flex-row gap-x-8'>
          <div className='w-full pb-6 mx-auto px-12'>
            <div className='flex flex-row-reverse justify-between items-center mt-4 mb-4'>
              {/* <Link to={`new`} className='flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
                <PlusIcon className={`h-5 w-5`} />
                Create review form
              </Link> */}
            </div>

            {reviewsSelector.loadingReviews ? 
              <Preloader preloadingText={`Loading reviews`} /> 
            :   
              <>
                {reviewsSelector.reviews?.reviews?.length > 0 ?
                    <>                                        
                      <div className='w-full grid grid-cols-2 xl:grid-cols-3 gap-[20px]'>
                          {reviewsSelector?.reviewForms?.forms?.map((question, questionIndex)=>(
                            <div key={questionIndex} className="bg-white my-[20px]">
                              {/* <ReviewFormCard form={question} doDelete={()=>{deleteForm(question._id)}} /> */}
                            </div>
                          ))}
                      </div>

                      <Pagination
                        pagination={{
                          currentPage: page,
                          perPage: perPage,
                          totalItems: reviewsSelector?.reviewForms?.total
                        }}
                        changePage={(value)=>{setPage(value)}}
                        updatePerPage={(value)=>{setPerPage(value)}}
                      />
                  </>
                  :
                  <EmptyState emptyStateText={`No reviews created yet.`} />
                }
              </>
            }
          </div>
        </div>
      </div>
    </ReviewsManagementLayout>
  )
}

export default Reviews