import React, { useState } from 'react'
import ReviewsManagementLayout from '../../../../components/layout/ReviewsManagementLayout'
import { useEffect } from 'react'
import { authHeader, userDetails } from '../../../../utils';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { ERROR } from '../../../../store/types';
import Preloader from '../../../../components/elements/Preloader';
import ReviewQuestion from '../../../../components/partials/reviews-management/ReviewQuestion';

const ReviewForm = () => {
  const {formId} = useParams()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);
  const [reviewForm, setReviewForm] = useState({});

  useEffect(() => {
    const fetchReviewForm = async () => {    
      try{
        const headers = authHeader()
        let requestUrl = `reviews/review-forms/${formId}?expand=sections.questions.question`
        setLoading(true)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })

        setReviewForm(response.data.data)
        setLoading(false)
      }
      catch(error){
        dispatch( {
            type: ERROR,
            error
        })
      }
    }

    fetchReviewForm()
    
    return () => {
      
    };
  }, [formId, dispatch]);

  return (
    <ReviewsManagementLayout sectionTitle={`Review Form`}>
    {loading ? <Preloader preloadingText={`Loading article`} /> :
      <div className='bg-white p-5 w-10/12 mt-[25px] relative'>
        <div className='flex flex-row-reverse'>
          {(userDetails().accountPermissions?.includes('*') || userDetails().accountPermissions?.includes('reviews.*') || userDetails().accountPermissions?.includes('reviews.update')) && <Link to={`/user/reviews-management/review-forms/edit/${formId}`} className='bg-gray-300 text-black p-3 rounded-md border border-gray-600 text-xs font-medium'>Edit Form</Link>}
        </div>
        <h3 className='font-[550] mb-[10px]'>{reviewForm.name}</h3>
        <p className='text-sm mb-[10px]'>{reviewForm.description}</p>

        {reviewForm.sections.map((section, sectionIndex) => (
          <div key={sectionIndex} className='mt-[25px]'>
            <h3 className='font-[550] text-sm mb-[10px] uppercase'>{section.title}</h3>
            <p className='text-sm mb-[10px]'>{section.description}</p>

            {section.questions.map((question, questionIndex) => (
              <div key={questionIndex} className='w-full my-[15px] pb-[20px] border-b border-gray-300'>
                <ReviewQuestion question={question.question} />
              </div>
            ))}
          </div>
        ))}
      </div>
    }
    </ReviewsManagementLayout>
  )
}

export default ReviewForm