import { CREATE_REVIEW, CREATE_REVIEW_FORM, CREATE_REVIEW_QUESTION, CREATING_REVIEW, CREATING_REVIEW_FORM, CREATING_REVIEW_QUESTION, DELETE_REVIEW_FORM, DELETE_REVIEW_QUESTION, DELETING_REVIEW_FORM, DELETING_REVIEW_QUESTION, GET_REVIEW_FORMS, GET_REVIEW_QUESTIONS, GET_REVIEW_SCHEDULES, GET_REVIEWS, GETTING_REVIEW_FORMS, GETTING_REVIEW_QUESTIONS, GETTING_REVIEW_SCHEDULES, GETTING_REVIEWS, REVIEWS_ERROR, UPDATE_REVIEW_FORM, UPDATE_REVIEW_QUESTION, UPDATING_REVIEW_FORM, UPDATING_REVIEW_QUESTION } from "../types"

const initialState = {
    reviewForms: [],
    reviewQuestions: [],
    reviews: [],
    reviewSchedules: [],
    loadingReviews: true,
    loadingReviewForms: true,
    loadingReviewSchedules: true,
    loadingReviewQuestions: true,
    reviewsError: null,
    creatingReview: false,
    creatingReviewForm: false,
    creatingReviewQuestion: false,
    createdReview: null,
    createdReviewForm: null,
    createdReviewQuestion: null,
    deletingReviewForm: false,
    deletedReviewForm: null,
    deletingReviewQuestion: false,
    deletedReviewQuestion: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_REVIEW_SCHEDULES:
        return {
            ...state,
            loadingReviews: action.payload,
            // fetchingMembers:false
        }
        case GET_REVIEW_SCHEDULES:
        return{
            ...state,
            loadingReviewSchedules: false,
            reviewsError: null,
            reviewSchedules: action.payload,
        }
        case GETTING_REVIEWS:
        return {
            ...state,
            loadingReviews: action.payload,
            // fetchingMembers:false
        }
        case GET_REVIEWS:
        return{
            ...state,
            loadingReviews: false,
            reviewsError: null,
            reviews: action.payload,
        }
        case CREATING_REVIEW:
        return {
            ...state,
            creatingReview: action.payload,
            // fetchingMembers:false
        }
        case CREATE_REVIEW:
        return{
            ...state,
            creatingReview: false,
            reviewsError: null,
            createdReview: action.payload,
        }
        case GETTING_REVIEW_FORMS:
        return {
            ...state,
            loadingReviewForms: action.payload,
            // fetchingMembers:false
        }
        case GET_REVIEW_FORMS:
        return{
            ...state,
            loadingReviewForms:false,
            reviewsError: null,
            reviewForms: action.payload,
        }
        case CREATING_REVIEW_FORM:
        return {
            ...state,
            creatingReviewForm: action.payload,
            // fetchingMembers:false
        }
        case CREATE_REVIEW_FORM:
        return{
            ...state,
            creatingReviewForm:false,
            reviewsError: false,
            createdReviewForm: action.payload,
        }
        case UPDATING_REVIEW_FORM:
        return {
            ...state,
            updatingReviewForm: action.payload,
            // fetchingMembers:false
        }
        case UPDATE_REVIEW_FORM:
        return{
            ...state,
            updatingReviewForm:false,
            reviewsError: false,
            updatedReviewForm: action.payload,
        }
        case DELETING_REVIEW_FORM:
        return {
            ...state,
            deletingReviewForm: action.payload,
        }
        case DELETE_REVIEW_FORM:
        return{
            ...state,
            deletingReviewForm: false,
            reviewsError: null,
            deletedReviewForm: action.payload,
        }
        case GETTING_REVIEW_QUESTIONS:
        return {
            ...state,
            loadingReviewQuestions: action.payload,
            // fetchingMembers:false
        }
        case GET_REVIEW_QUESTIONS:
        return{
            ...state,
            loadingReviewQuestions:false,
            reviewsError: null,
            reviewQuestions: action.payload,
        }
        case CREATING_REVIEW_QUESTION:
        return {
            ...state,
            creatingReviewQuestion: action.payload,
        }
        case CREATE_REVIEW_QUESTION:
        return{
            ...state,
            creatingReviewQuestion:false,
            reviewsError: false,
            createdReviewQuestion: action.payload,
        }
        case UPDATING_REVIEW_QUESTION:
        return {
            ...state,
            updatingReviewQuestion: action.payload,
            // fetchingMembers:false
        }
        case UPDATE_REVIEW_QUESTION:
        return{
            ...state,
            updatingReviewQuestion:false,
            reviewsError: false,
            updatedReviewQuestion: action.payload,
        }
        case DELETING_REVIEW_QUESTION:
        return {
            ...state,
            deletingReviewQuestion: action.payload,
        }
        case DELETE_REVIEW_QUESTION:
        return{
            ...state,
            deletingReviewQuestion: false,
            reviewsError: null,
            deletedReviewQuestion: action.payload,
        }
        case REVIEWS_ERROR:
        return{
            ...state,
            loadingReviewSchedules: true,
            loadingReviews: true,
            loadingReviewForms: true,
            loadingReviewQuestions: true,
            creatingReview: false,
            creatingReviewForm: false,
            creatingReviewQuestion: false,
            createdReview: null,
            createdReviewForm: null,
            createdReviewQuestion: null,
            deletingReviewForm: false,
            deletedReviewForm: null,
            deletingReviewQuestion: false,
            deletedReviewQuestion: null,
            reviewsError: action.payload 
        }
        default: return state
    }

}