import React, { useState } from 'react'
import Checkbox from '../../elements/form/Checkbox'
import FormButton from '../../elements/form/FormButton';
import { unSlugify } from '../../../utils';

const QuestionsSelector = ({questions, selectedQuestions, saveNewList, closeModal}) => {
    const [questionsList, setQuestionsList] = useState(selectedQuestions || []);
    
    const toggleQuestionSelection = (questionToToggle) => {
        const tempList = [...questionsList]
        const index = tempList.findIndex((question) => 
            question.question._id === questionToToggle._id
        )

        if(index > -1) {
            tempList.splice(index, 1)
        } else {
            tempList.push({order: questionsList.length, question: questionToToggle})
        }
        // console.log(tempList)
        setQuestionsList(tempList)
    }

    const questionSelected = (questionToCheck) => {
        const index = questionsList.findIndex(question => question.question._id === questionToCheck._id)
        return index > -1
    }

    const updateAndClose = () => {
        saveNewList(questionsList)
        closeModal()
    }

    return (
        <div>
            <div className='max-h-[500px] overflow-y-scroll scrollbar-hidden'>
            {questions?.length > 0 ? 
                <div className='w-full'>
                    {questions?.map((question, questionIndex) => (
                    <div key={questionIndex} className={`flex items-start gap-x-2 mb-4 cursor-pointer`} onClick={(()=>{toggleQuestionSelection(question)})}>
                        <div className='w-[25px] pt-[5px]'>
                            <Checkbox 
                                isChecked={questionSelected(question)}
                                checkboxToggleFunction={()=>{toggleQuestionSelection(question)}}
                                checkboxLabel=''
                            />
                        </div>
                        <div className='w-full'>
                            <p className='text-xs text-gray-700 font-medium'>{question.questionCode}</p>
                            <div className='my-1'>
                                <p className='text-xs'>{question.question.length > 100 ? question.question.slice(0, 100) + '...' : question.question}</p>
                            </div>
                            <div className='flex flex-row mt-[10px]'>
                                <span className='capitalize rounded border border-gray-400 text-gray-600 px-[5px] py-[3px] text-xs'>{unSlugify(question.fieldType)}</span>
                            </div>
                        </div>
                    </div>
                    ))}
                </div> 
                :
                <div className='p-5 bg-gray-100 rounded-[8px] tex-center'>
                    <p className='text-sm text-gray-700 font-[550]'>No Questions found</p>
                    <p className='mt-1 text-xs text-gray-500'>Navigate to the training materials page and create some materials first.</p>
                </div>  
                } 
            </div>
            <div className='flex flex-row-reverse mt-[20px]'>
                <div className='w-max'>
                    <FormButton buttonLabel={`Save Selection`} buttonAction={()=>{updateAndClose()}} />
                </div>
            </div>
        </div>
    )
}

export default QuestionsSelector