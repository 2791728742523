import React from 'react'
import LeavesHolidaysLayout from '../../../../components/layout/LeavesHolidaysLayout'

const LeaveApplication = () => {
  return (
      <LeavesHolidaysLayout>
        LeaveApplication
      </LeavesHolidaysLayout>
  )
}

export default LeaveApplication