import React from 'react'

const OrderStatus = ({status}) => {
  return (
    <>
        {status === "in_evaluation" &&
            <span className="px-2 py-1 rounded text-blue-600 font-medium bg-blue-400 bg-opacity-10 text-xs">
                {/* <Circle width={15} /> */}
                In evaluation
            </span>
        }
        {status === "in_progress" &&
            <span className="px-2 py-1 rounded text-blue-600 font-medium bg-blue-400 bg-opacity-10 text-xs">
                {/* <Circle width={15} /> */}
                In progress
            </span>
        }
        {status === "done" &&
            <span className="px-2 py-1 rounded text-green-600 font-medium bg-green-400 bg-opacity-10 block text-xs">
                {/* <CheckIcon /> */}
                Done
            </span>
        }
        {status === "active" &&
            <span className="px-2 py-1 rounded text-green-600 font-medium bg-green-400 bg-opacity-10 block text-xs">
                {/* <CheckIcon /> */}
                Active
            </span>
        }
        {status === "completed" &&
            <span className="px-2 py-1 rounded text-green-600 font-medium bg-green-400 bg-opacity-10 block text-xs">
                {/* <CheckIcon /> */}
                Completed
            </span>
        }
        {status === "JOINED" &&
            <span className="px-2 py-1 rounded text-green-600 font-medium bg-green-400 bg-opacity-10 block text-xs">
                {/* <CheckIcon /> */}
                Joined
            </span>
        }
        {status === "SUCCESSFUL" &&
            <span className="px-2 py-1 rounded text-green-600 font-medium bg-green-400 bg-opacity-10 block text-xs">
                {/* <CheckIcon /> */}
                Paid
            </span>
        }
        {status === "SIGNED_OFF" &&
            <span className="px-2 py-1 rounded text-green-600 font-medium bg-green-400 bg-opacity-10 block text-xs">
                {/* <CheckIcon /> */}
                Signed off
            </span>
        }
        {status === "APPROVED" &&
            <span className="px-2 py-1 rounded text-green-600 font-medium bg-green-400 bg-opacity-10 block text-xs">
                {/* <CheckIcon /> */}
                Signed off
            </span>
        }
        {status === "in_review" &&
            <span className="px-2 py-1 rounded text-yellow-600 bg-yellow-200 bg-opacity-20 block text-xs">
                {/* <CloseIcon width={22} /> */}
                In review
            </span>
        }
        {status === "FAILED" &&
            <span className="px-2 py-1 rounded text-red-600 bg-red-200 bg-opacity-20 block text-xs">
                {/* <CloseIcon width={22} /> */}
                Failed
            </span>
        }
        {status === "backlog" &&
            <span className="px-2 py-1 rounded text-gray-600 bg-gray-400 bg-opacity-10 block text-xs">
                {/* <CloseIcon width={22} /> */}
                Backlog
            </span>
        }
        {(status === "PENDING" || status === "pending" || status === "todo") &&
            <span className="px-2 py-1 rounded text-gray-600 bg-gray-400 bg-opacity-10 block text-xs capitalize">
                {/* <CloseIcon width={22} /> */}
                {status}
            </span>
        }
        {status === "SUBMITTED" &&
            <span className="px-2 py-1 rounded text-gray-600 bg-gray-400 bg-opacity-10 block text-xs">
                {/* <CloseIcon width={22} /> */}
                Submitted
            </span>
        }
    </>
  )
}

export default OrderStatus