import React, { useEffect, useState } from 'react'
import TextField from '../../elements/form/TextField'
import TextareaField from '../../elements/form/TextareaField'
// import AutocompleteSelect from '../../elements/form/AutocompleteSelect'
import FormButton from '../../elements/form/FormButton'
import RadioGroup from '../../elements/form/RadioGroup'
import SelectField from '../../elements/form/SelectField'
import { useDispatch, useSelector } from 'react-redux'
import { ERROR } from '../../../store/types'
import DateField from '../../elements/form/DateField'
import { fetchEmployees } from '../../../store/actions/employeeActions'
import { deleteTask, updateTask } from '../../../store/actions/tasksActions'
import { formatDate, userDetails } from '../../../utils'
import TrashIcon from '../../elements/icons/TrashIcon'
import PencilSquareIcon from '../../elements/icons/PencilSquareIcon'
import OrderStatus from '../../elements/OrderStatus'
import UserIcon from '../../elements/icons/UserIcon'

const TaskDetails = ({task}) => {
    const dispatch = useDispatch()
    const employeesSelector = useSelector(state => state.employees)
    const tasksSelector = useSelector(state => state.tasks)
    useEffect(() => {
        dispatch(fetchEmployees())
        return () => {
            
        };
    }, [dispatch]);

    const [updatingTask, setUpdatingTask] = useState(false);
    const [taskPayload, setTaskPayload] = useState(task);
    const [validationErrors, setValidationErrors] = useState({});
    const accountPermissions = userDetails().accountPermissions

    const validateForm = () => {
        let errors = {}
        if(!taskPayload.title || taskPayload.title === ''){
            errors.title = true
        }
        setValidationErrors(errors)
        return errors
    }

    const pushUpdate = () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }

        const payload = taskPayload
        // if(taskStatus && taskStatus !== ''){
        //     payload.status = taskStatus
        // }
        // if(parentTask && parentTask !== ''){
        //     payload.parent = parentTask
        // }
        dispatch(updateTask(task._id, payload))
    }

    const pushTaskDelete = () => {
        if(window.confirm("Are you sure you want to delete this task?")) {
            dispatch(deleteTask(task._id))
        }
    }

    const priorities = [
        {
            label: 'Urgent',
            value: 'urgent'
        },
        {
            label: 'High',
            value: 'high'
        },
        {
            label: 'Medium',
            value: 'medium'
        },
        {
            label: 'Low',
            value: 'low'
        },
    ]

    return (
        <div>
            <div className='flex items-center flex-row-reverse gap-x-[5px]'>
                {(accountPermissions?.includes('*') || accountPermissions?.includes('tasks.*') || accountPermissions?.includes('tasks.delete')) && <button onClick={()=>{pushTaskDelete()}} className='transition duration-200 hover:text-red-700 p-1 text-gray-600 rounded'>
                    <TrashIcon className={`w-5 h-5`} />
                </button>}
                {(accountPermissions?.includes('*') || accountPermissions?.includes('tasks.*') || accountPermissions?.includes('tasks.update')) && <button onClick={()=>{setUpdatingTask(!updatingTask)}} className='transition duration-200 hover:text-gray-700 p-1 text-gray-600 rounded'>
                    <PencilSquareIcon className={`w-5 h-5`} />
                </button>}
            </div>
            {updatingTask ? <div className='w-full p-[20px'>
                <div className='my-1 w-full'>
                    <TextField
                        inputLabel="Title" 
                        fieldId="task" 
                        inputType="text" 
                        preloadValue={taskPayload.title || ''}
                        hasError={validationErrors && validationErrors.title} 
                        returnFieldValue={(value)=>{setTaskPayload({...taskPayload, ...{title: value}})}}
                    />
                </div>
                <div className='my-1 w-full'>
                    <TextareaField
                        inputLabel="Description" 
                        fieldId="task-description" 
                        inputType="text" 
                        preloadValue={taskPayload.description || ''}
                        hasError={false} 
                        returnFieldValue={(value)=>{setTaskPayload({...taskPayload, ...{description: value}})}}
                    />
                </div>
                
                <div className='mt-3 mb-1'>
                    <label 
                    className={`cursor-text bg-transparent text-sm z-10 font-[550] transition duration-200  
                    text-gray-800 `}>
                        Assign this task
                    </label>
                    <div className='my-1 w-full'>
                    {employeesSelector?.employees.length > 0 ?
                        <SelectField
                            selectOptions={employeesSelector?.employees}
                            inputLabel="Select Assignee"
                            titleField="fullName"
                            displayImage={false}
                            imageField=""
                            fieldId="task-assignee"
                            preSelectedLabel={`_id`}
                            preSelected={taskPayload.assignee._id || ''}
                            hasError={false}
                            returnFieldValue={(value) => {setTaskPayload({...taskPayload, ...{assignee: value._id}})}}
                        />
                        :
                        <div className='rounded p-[15px] bg-gray-100 text-center'>
                            <p className='text-gray-600 text-xs'>No employees are fully onboarded yet. You will be able to assign this task to an employee when you have onnboarded employees.</p>
                        </div>
                    }
                    </div>
                </div>

                <div className='my-1 w-full'>
                    <DateField
                        inputLabel="Due date" 
                        fieldId="task-due-date" 
                        inputType="date" 
                        preloadValue={taskPayload.dueDate || ''}
                        hasError={false} 
                        returnFieldValue={(value)=>{setTaskPayload({...taskPayload, ...{dueDate: value}})}}
                    />
                </div>

                <div className='my-1 w-full'>
                    <RadioGroup
                        inputLabel="Task priority" 
                        items={priorities}
                        preSelectedIndex={priorities.findIndex(priority => priority.value === taskPayload.priority) }
                        hasError={false} 
                        inline={true}
                        returnSelected={(value)=>{setTaskPayload({...taskPayload, ...{priority: value.value}})}}
                    />
                </div>

                <div className='mt-3'>
                    <FormButton buttonLabel="Create task" buttonAction={()=>{pushUpdate()}} processing={tasksSelector.creatingTask} />
                </div>
            </div> : 
            <div className='w-full'>
                <div className='mb-[20px]'>
                    <label className='text-xs text-gray-400 tracking-[0.2em] block mb-[5px] uppercase'>TITLE</label>
                    <p className='text-sm font-medium'>{task.title}</p>
                </div>
                <div className='mb-[20px]'>
                    <label className='text-xs text-gray-400 tracking-[0.2em] block mb-[5px] uppercase'>priority</label>
                    <span className={`${task.priority === 'urgent' && 'bg-red-400 text-white text-opacity-90'} ${task.priority === 'high' && 'bg-red-400 bg-opacity-10 text-red-500'} ${task.priority === 'medium' && 'bg-gray-400 bg-opacity-10 text-gray-500'} ${task.priority === 'low' && 'bg-blue-400 bg-opacity-10 text-blue-500'} inline-block  uppercase text-[10px] py-1 px-2 font-medium`}>{task.priority} priority
                    </span>
                </div>
                <div className='mb-[20px]'>
                    <label className='text-xs text-gray-400 tracking-[0.2em] block mb-[5px] uppercase'>description</label>
                    <p className='text-sm'>{task.description}</p>
                </div>
                {task.dueDate &&<div className='mb-[20px]'>
                    <label className='text-xs text-gray-400 tracking-[0.2em] block mb-[5px] uppercase'>due date</label>
                    <p className='text-sm'>{formatDate(new Date(task.dueDate))}</p>
                </div>}

                <div className='flex items-center justify-between mb-[20px]'>
                    <div className='flex items-center gap-x-2'>
                        <div className='w-[40px] h-[40px] rounded-full border border-gray-400 bg-gray-100 flex items-center justify-center'>
                            <UserIcon className={`w-5 h-5 rounded-sm text-gray-400 `} />
                        </div>
                        <div>
                            <p className='text-gray-600 text-xs'>{task.assignee.fullName}</p>
                            <p className='text-gray-6400 text-xs mt-1 font-thin'>{task.assignee.department.name}</p>
                        </div>
                    </div>
                    <OrderStatus status={task.status} />
                </div>
            </div>
            }
        </div>
    )
}

export default TaskDetails