import React, { useState } from 'react'
import ChevronIcon from './icons/ChevronIcon'
import CloseIcon from './icons/CloseIcon'
import FilterSlidesIcon from './icons/FilterSlidesIcon'

const Filters = ({filterLabel, filterOptions, returnSelected, resetFilters}) => {
    const [optionsActive, setOptionsActive] = useState(false)
    const [activeFilterOption, setActiveFilterOption] = useState(null)
    const [operators, setOperators] = useState([])
    // const [selectedOperator, setSelectedOperator] = useState(null)

    const toggleFilters = () => {
        setActiveFilterOption(null)
        setOperators([])
        setOptionsActive(!optionsActive)
    }

    const selectFilterOption = (index) => {
        setActiveFilterOption(null)
        setOperators(filterOptions[index].operators)
        setTimeout(() => {
            setActiveFilterOption(index)
        }, 200);
    }

    const selectOperator = (index) => {
        const tempOperators = [ ...operators ]
        tempOperators.forEach(((operator) => {
            operator.selected = false
        }))
        tempOperators[index].selected = !tempOperators[index].selected
        setOperators(tempOperators)
    }

    const [filterTerm, setFilterTerm] = useState('')

    const [activeFilters, setActiveFilters] = useState([])

    const addToActiveFilters = (filter) => {
        const tempActiveFilters = [...activeFilters]
        tempActiveFilters.push(filter)
        setActiveFilters(tempActiveFilters)
    }   

    const addFilter = () => {
        const selectedOperator = operators.find((operator) => {
            return operator.selected === true
        })

        let filter = {
            field: filterOptions[activeFilterOption].value,
            operator: selectedOperator.value,
            term: filterTerm
        }

        addToActiveFilters({...filter, 
            ...{ operatorLabel: selectedOperator.name}, 
            ...{ fieldLabel: filterOptions[activeFilterOption].field}
        })

        if(filterOptions[activeFilterOption].type === 'currency') {
            filter.term = filter.term * 100
        }

        setTimeout(() => {
            returnSelected(filter)
        }, 100);

        toggleFilters()
    }

    // const removeFilter = () => {

    // }

    const clearFilters = () => {
        setActiveFilters([])
        resetFilters()
    }

    return (
        <div className='flex items-center gap-x-3 w-full'>
            {activeFilters.length > 0 && 
                <>
                    {activeFilters.map((filter, filterIndex)=>(
                        <div key={filterIndex} className='rounded min-w-[300px] border border-gray-400 text-black px-3 py-2 text-sm flex items-center justify-between gap-2 bg-gray-200'>
                            <p className='text-sm'><span className='font-medium'>{filter.fieldLabel}</span> <span className='font-thin'>{filter.operatorLabel}</span> <span className='font-medium'>{filter.term}</span> </p>
                            {/* <button>
                                <CloseIcon className={`w-4 h-4`} />
                            </button> */}
                        </div>
                    ))}
                    <button className='p-2 bg-red-200 border border-gray-400 rounded' onClick={()=>{clearFilters()}}>
                        <CloseIcon className={`w-5 h-5`} />
                    </button>
                </>
            }
            <div className='relative py-2 z-50 w-full'>
                <button className='rounded border border-gray-400 text-black px-3 py-2 text-sm flex items-center gap-x-2' onClick={()=>{toggleFilters()}}>
                    <FilterSlidesIcon className={`w-5 h-5`} />
                    <span className=''>{filterLabel} </span>
                    <ChevronIcon className={`w-4 h-4 transform ${optionsActive ? '-rotate-180' : '' }`} />
                </button>

                {optionsActive && 
                    <div className='absolute top-[65px] left-0 flex gap-x-[10px]'>
                        <div className='relative'>
                            <div className="bg-white border rounded border-gray-400">
                                {filterOptions.map((option, optionIndex)=>(
                                    <div key={optionIndex} className="">
                                        <button className={`flex justify-between gap-x-6 items-center w-full px-4 py-3 text-sm ${activeFilterOption === optionIndex ? 'bg-black text-white' : ''}`} onClick={()=>{selectFilterOption(optionIndex)}}>
                                            <span>{option.field}</span>
                                            <ChevronIcon className={`w-4 h-4 transform -rotate-90`} />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {activeFilterOption !== null && operators.length > 0 &&
                            <div className='relative'>
                                <div className="bg-white border rounded border-gray-400">
                                    {operators.map((operator, operatorIndex)=>(
                                        <div key={operatorIndex} className="">
                                            <button className='flex gap-x-4 items-center w-full px-4 py-3 text-sm' onClick={()=>{selectOperator(operatorIndex)}}>
                                                <div className='w-3'>
                                                    <div className={`w-[10px] h-[10px] rounded-full border border-gray-400 ${operator.selected === true ? 'bg-black' : ''}`} />
                                                </div>
                                                <span>{operator.name}</span>
                                            </button>
                                        </div>
                                    ))}

                                    <div className='w-full p-4'>
                                        {filterOptions[activeFilterOption].type === 'select' && 
                                            <select className='w-full rounded border border-gray-400 px-3 py-2 text-sm' onChange={(e)=>{setFilterTerm(e.target.value)}}>
                                                <option value="">Select option</option>
                                                {filterOptions[activeFilterOption].termOptions.map((term, termIndex)=>(
                                                    <option key={termIndex} value={term}>{term}</option>
                                                ))}
                                            </select> 
                                        }

                                        {(filterOptions[activeFilterOption].type === 'number' || filterOptions[activeFilterOption].type === 'currency') && 
                                            <input className='w-full rounded border border-gray-400 px-3 py-2 text-sm' placeholder='Enter value' type="number" onChange={(e)=>{setFilterTerm(e.target.value)}} />    
                                        }

                                        {filterOptions[activeFilterOption].type === 'date' && 
                                            <input className='w-full rounded border border-gray-400 px-3 py-2 text-sm' placeholder='' type="date" onChange={(e)=>{setFilterTerm(e.target.value)}} />    
                                        }

                                        <button className='mt-3 px-4 py-2 border border-gray-400 rounded text-sm bg-allawee-card-green text-black w-full' onClick={()=>{addFilter()}}>
                                            Add Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                </div>}
            </div>
        </div>
    )
}

export default Filters