import React from 'react'
import UserIcon from '../../elements/icons/UserIcon'
import { userDetails } from '../../../utils'

const EmployeeSnippet = ({name, phone, email, showIcon, userId}) => {
    return (
      <div className='flex items-center gap-x-3'>
        {showIcon && 
          <div className='w-[40px] h-[40px] rounded-full border border-gray-400 bg-gray-100 flex items-center justify-center'>
            <UserIcon className={`w-5 h-5 rounded-sm text-gray-400 `} />
          </div>
        }
          
          <div>
              <p className='font-medium text-gray-600 text-sm mb-[5px]'>{name} {userId && userId === userDetails()._id && <span className='px-[7px] py-[2px] ml-[5px] rounded bg-gray-200 text-xs'>You</span>}</p>
              <p className='text-gray-6400 text-xs'>{email} {phone ? ', ' : '' } {phone}</p>
          </div>
      </div>
    )
  }

export default EmployeeSnippet