import React, { useEffect, useState } from 'react'
import ReviewsManagementLayout from '../../../../components/layout/ReviewsManagementLayout'
import EmptyState from '../../../../components/elements/icons/EmptyState'
import PlusIcon from '../../../../components/elements/icons/PlusIcon'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearDeletedReviewForm, deleteReviewForm, getReviewForms } from '../../../../store/actions/reviewsActions'
import { SET_SUCCESS_MESSAGE } from '../../../../store/types'
import Pagination from '../../../../components/elements/Pagination'
import Preloader from '../../../../components/elements/Preloader'
import ReviewFormCard from '../../../../components/partials/reviews-management/ReviewFormCard'

const ReviewForms = () => {
  const reviewsSelector = useSelector(state => state.reviews)
  const dispatch = useDispatch()
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);

  useEffect(() => {
      dispatch(getReviewForms(page, perPage))
      if(reviewsSelector.deletedReviewForm !== null){
          dispatch({
              type: SET_SUCCESS_MESSAGE,
      payload: {
        successMessage: 'Review form deleted'
      }
          })
          dispatch(clearDeletedReviewForm())
      }
      return () => {
          
      };
  }, [dispatch, page, perPage, reviewsSelector.deletedReviewForm]);

  // const [creatingReviewQuestion, setCreatingReviewQuestion] = useState(false);

  const deleteForm = (id) => {
      if(window.confirm('Are you sure you want to delete this review form?')) {
          dispatch(deleteReviewForm(id))
      }
  }
  
  return (
    <ReviewsManagementLayout sectionTitle={`Review Forms`}>
      <div className=''>
        <div className='w-full lg:flex flex-row gap-x-8'>
          <div className='w-full pb-6 mx-auto px-12'>
            <div className='flex flex-row-reverse justify-between items-center mt-4 mb-4'>
              <Link to={`new`} className='flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
                <PlusIcon className={`h-5 w-5`} />
                Create review form
              </Link>
            </div>

            {reviewsSelector.loadingReviewForms ? 
              <Preloader preloadingText={`Loading review forms`} /> 
            :   
              <>
                {reviewsSelector.reviewForms?.forms?.length > 0 ?
                    <>                                        
                      <div className='w-full grid grid-cols-2 xl:grid-cols-3 gap-[20px]'>
                          {reviewsSelector?.reviewForms?.forms?.map((question, questionIndex)=>(
                            <div key={questionIndex} className="bg-white my-[20px]">
                              <ReviewFormCard form={question} doDelete={()=>{deleteForm(question._id)}} />
                            </div>
                          ))}
                      </div>

                      <Pagination 
                        pagination={{
                          currentPage: page,
                          perPage: perPage,
                          totalItems: reviewsSelector?.reviewForms?.total
                        }}
                        changePage={(value)=>{setPage(value)}}
                        updatePerPage={(value)=>{setPerPage(value)}}
                      />
                  </>
                  :
                  <EmptyState emptyStateText={`No review forms created yet, use the "create review form" button above to create a new one.`} />
                }
              </>
            }
          </div>
        </div>
      </div>
    </ReviewsManagementLayout>
  )
}

export default ReviewForms