import React, { useEffect, useState } from 'react'
import PlayerLayout from '../../../../components/layout/PlayerLayout'
import Preloader from '../../../../components/elements/Preloader'
import { ERROR } from '../../../../store/types'
import axios from 'axios'
import { authHeader } from '../../../../utils'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ReviewQuestion from '../../../../components/partials/reviews-management/ReviewQuestion'
import FormButton from '../../../../components/elements/form/FormButton'

const NewReview = () => {
    const {formId, scheduleId} = useParams()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    const [reviewForm, setReviewForm] = useState({});
    const [reviewSchedule, setReviewSchedule] = useState({});
  
    useEffect(() => {
      const fetchReviewForm = async () => {    
        try{
          const headers = authHeader()
          let requestUrl = `reviews/review-forms/${formId}?expand=sections.questions.question`
          setLoading(true)
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })
  
          setReviewForm(response.data.data)
            fetchReviewSchedule()
        }
        catch(error){
          dispatch( {
              type: ERROR,
              error
          })
        }
      }

      const fetchReviewSchedule = async () => {    
        try{
          const headers = authHeader()
          let requestUrl = `reviews/schedules/${scheduleId}?expand=employee`
          setLoading(true)
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })
  
          setReviewSchedule(response.data.data)
          setLoading(false)
        }
        catch(error){
          dispatch( {
              type: ERROR,
              error
          })
        }
      }
  
      fetchReviewForm()
      
      return () => {
        
      };
    }, [formId, scheduleId, dispatch]);

    return (
        <PlayerLayout pageTitle={`Submit Review`} >
        {loading ? <Preloader preloadingText={`Loading article`} /> :
        <div className='bg-white p-5 w-full mx-auto xl:w-10/12 mt-[25px] relative'>

            <h3 className='font-thin mb-[25px] uppercase tracking-[0.5em] text-sm'>Creating review for {reviewSchedule.employee.fullName}</h3>
            
            <div className='w-full h-[1px] bg-gray-400 mb-[25px]' />
            
            <h3 className='font-[550] mb-[10px]'>{reviewForm.name}</h3>
            <p className='text-sm mb-[10px]'>{reviewForm.description}</p>

            {reviewForm.sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className='mt-[25px]'>
                <h3 className='font-[550] text-sm mb-[10px] uppercase'>{section.title}</h3>
                <p className='text-sm mb-[10px]'>{section.description}</p>

                {section.questions.map((question, questionIndex) => (
                <div key={questionIndex} className='w-full my-[15px] pb-[20px] border-b border-gray-300'>
                    <ReviewQuestion question={question.question} />
                </div>
                ))}
            </div>
            ))}

            <div className='mt-[50px] w-max'>
                <FormButton buttonLabel={`Submit Review for ${reviewSchedule.employee.firstName}`} />
            </div>
        </div>
        }</PlayerLayout>
    )
}

export default NewReview