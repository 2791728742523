import React, { useEffect, useMemo, useState } from 'react'
import ReviewsManagementLayout from '../../../../components/layout/ReviewsManagementLayout'
import { useDispatch, useSelector } from 'react-redux'
import { ERROR, SET_SUCCESS_MESSAGE } from '../../../../store/types'
import { clearCreatedReviewForm, createReviewForm, getReviewQuestions } from '../../../../store/actions/reviewsActions'
import TextareaField from '../../../../components/elements/form/TextareaField'
import TextField from '../../../../components/elements/form/TextField'
import TrashIcon from '../../../../components/elements/icons/TrashIcon'
import PlusIcon from '../../../../components/elements/icons/PlusIcon'
import FormButton from '../../../../components/elements/form/FormButton'
import ModalLayout from '../../../../components/layout/ModalLayout'
import DragDropList from '../../../../components/elements/DragDropList'
import SelectField from '../../../../components/elements/form/SelectField'
import QuestionsSelector from '../../../../components/partials/reviews-management/QuestionsSelector'
import { authHeader, unSlugify } from '../../../../utils'
import Preloader from '../../../../components/elements/Preloader'
import axios from 'axios'
import { useParams } from 'react-router-dom'

const EditReviewForm = () => {
    const section = useMemo(() => ({
        title: '',
        description: '',
        questions: []
    }), []);

    const dispatch = useDispatch()
    const reviewsSelector = useSelector(state => state.reviews)
    const [formPayload, setFormPayload] = useState({sections: [section]});
    const [validationErrors, setValidationErrors] = useState({});
    const [display, setDisplay] = useState(true);
    const [addingQuestions, setAddingQuestions] = useState(false);
    const [loading, setLoading] = useState(true);
    const {formId} = useParams()

    useEffect(() => {
    const fetchReviewForm = async () => {    
        try{
            const headers = authHeader()
            let requestUrl = `reviews/review-forms/${formId}?expand=sections.questions.question`
            setLoading(true)
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })
    
            setFormPayload(response.data.data)
            setLoading(false)
        }
        catch(error){
            dispatch( {
                type: ERROR,
                error
            })
        }
    }

    fetchReviewForm()
    dispatch(getReviewQuestions(0, 0))
    if(reviewsSelector.createdReviewForm !== null) {
        dispatch({
            type: SET_SUCCESS_MESSAGE,
            payload: {
                successMessage: 'Review form created successfully. Please fill the form again to create another form'
            }
        })
        dispatch(clearCreatedReviewForm())
        setDisplay(false)
        setFormPayload({sections: [{...section, ...{questions: []}}]})
        setTimeout(() => {
            setDisplay(true)
        }, 50);
    }
    
    return () => {
        
    };

    }, [reviewsSelector.createdReviewForm, dispatch, section, formId]);

    const validateForm = () => {
    let errors = {}
        if (!formPayload.name || formPayload.name === '') {
            errors.name = true
        }
        if (!formPayload.description || formPayload.description === '') {
            errors.description = true
        }
        if (!formPayload.frequency || formPayload.frequency === '') {
            errors.frequency = true
        }
        if(!formPayload.sections || formPayload.sections.length === 0){
        errors.section = true
        dispatch({
            type: ERROR,
            error: {response: {data: {
                message: 'Provide at least one section for this form'
            }}}
        })
        }

        formPayload.sections.forEach((section, sectionIndex) => {
        if(!section.title || section.title === ''){
            errors[`section-${sectionIndex}-title`] = true
        }
        if(!section.questions || section.questions.length === 0){
            errors[`section-${sectionIndex}-questions`] = true
            dispatch({
            type: ERROR,
            error: {response: {data: {
                message: 'Provide at least one question per section'
            }}}
            })
        }
        })
        
        setValidationErrors(errors)
        return errors
    }
    
      const pushForm = () => {
        if (Object.values(validateForm()).includes(true)) {
          dispatch({
              type: ERROR,
              error: {response: {data: {
                  message: 'Please check the highlighted fields'
              }}}
          });
          return
        }
    
        const payload = formPayload
    
        const newSections = payload.sections.map(section => {
          const sectionQuestions = section.questions.map((question => {
            return {
              order: question.order,
              question: question.question._id
            }
          }))
          return {...section, ...{questions: sectionQuestions}}
        })
    
        dispatch(createReviewForm({...payload, ...{sections: newSections}}))
      }
    
      const addSection = () => {
        let temp = {...formPayload}
        temp.sections.push({...section, ...{questions: []}})
        setFormPayload(temp)
      }
    
      const removeSection = (index) => {
        let temp = {...formPayload}
        temp.sections.splice(index, 1)
        setFormPayload(temp)
      }
    
      const updateSection = (index, field, value) => {
        let temp = {...formPayload}
        temp.sections[index][field] = value
        setFormPayload(temp)
      }
    
      // const removeQuestion = (sectionIndex, questionIndex) => {
      //   let temp = [...sections]
      //   temp[sectionIndex].questions.splice(questionIndex, 1)
      //   setSections(temp)
      // }
    
      const QuestionDraggable = ({data}) => {
        return (
          <div className='w-full'>
            <div className='flex gap-x-[10px] flex-row items-center mb-[10px]'>
              <p className='text-black font-[550]'>{data.question.questionCode}</p>
              <span className='capitalize rounded border border-gray-400 text-gray-600 px-[5px] py-[3px] text-xs'>{unSlugify(data.question.fieldType)}</span>
            </div>
            <div className='my-1'>
              <p className='text-xs'>{data.question.question.length > 100 ? data.question.question.slice(0, 100) + '...' : data.question.question}</p>
            </div>
          </div>
        )
      }
    
      const updateQuestionsList = (sectionIndex, updatedList) => {
        let temp = {...formPayload}
        temp.sections[sectionIndex].questions = updatedList
        setFormPayload(temp)
      } 
    
      const formFrequencies = [
        {
          label: 'Select Frequency',
          value: ''
        },
        {
          label: 'Daily',
          value: 'daily'
        },
        {
          label: 'Weekly',
          value: 'weekly'
        },
        {
          label: 'Monthly',
          value: 'monthly'
        },
        {
          label: 'Quarterly',
          value: 'quarterly'
        },
        {
          label: 'Bi-annually',
          value: 'biannually'
        },
        {
          label: 'Annually',
          value: 'annually'
        },
        {
          label: 'Custom frequency',
          value: 'custom'
        },
      ]
    
      const [activeSectionIndex, setActiveSectionIndex] = useState(null);
    
      const openSectionQuestionsAddition = (sectionIndex) => {
        setActiveSectionIndex(sectionIndex)
        setTimeout(() => {
          setAddingQuestions(true)
        }, 50);
      }
    
  return (
    <>
    <ReviewsManagementLayout sectionTitle={`New Review Form`}>

    {loading ? <Preloader preloadingText={`Loading article`} /> :

      display && <div className='w-7/12 my-[30px] bg-white p-[20px]'>
        <p className='text-sm'>Please provide details of the review form below</p>
        <div className='w-full'>
          <div className='mb-4 mt-2 w-full'>
            <TextField
              inputLabel="Name" 
              fieldId="form-name" 
              inputType="text" 
              preloadValue={formPayload.name || ''}
              hasError={validationErrors.name } 
              height={`120px`}
              returnFieldValue={(value)=>{setFormPayload({...formPayload, ...{name: value}})}}
            />
          </div>
          
          <div className='mb-4 mt-2 w-full'>
            <TextareaField
              inputLabel="Description" 
              fieldId="question-name" 
              inputType="text" 
              preloadValue={formPayload.description || ''}
              hasError={validationErrors.description} 
              height={`120px`}
              returnFieldValue={(value)=>{setFormPayload({...formPayload, ...{description: value}})}}
            />
          </div>

          {/* <div className='my-4 w-full'>
            <RadioGroup
                inputLabel="Field type (What type of field should be presented to the reviewer?)"
                inline={false}
                items={fieldTypes} 
                hasError={validationErrors.fieldType} 
                returnSelected={(value)=>{setQuestionPayload({...questionPayload, ...{fieldType: value.value}})}}
            />
          </div> */}

          <div className='mb-4 w-full'>
            <SelectField
                selectOptions={formFrequencies}
                inputLabel="Select Frequency"
                titleField="label"
                displayImage={false}
                imageField=""
                preSelected={formPayload.frequency || ''}
                preSelectedLabel={`value`}
                fieldId="form-frequency"
                hasError={validationErrors.frequency}
                // return id of accounts of the selected option
                returnFieldValue={(value) => {setFormPayload({...formPayload, ...{frequency: value.value}})}}
            />
          </div>

          {formPayload.frequency === 'custom' && <div className='mb-4 mt-2 w-full'>
            <TextField
              inputLabel="Custom frequency (in days)" 
              fieldId="form-name" 
              inputType="text" 
              preloadValue={''}
              hasError={validationErrors.question } 
              returnFieldValue={(value)=>{setFormPayload({...formPayload, ...{customFrequency: value}})}}
            />
          </div>}

          <div className='my-4 w-full'>
            <p className='text-sm mt-[20px]'>Form Sections</p>
            <p className='text-xs text-gray-500'>Please provide at least one section for this form</p>

            {formPayload.sections.map((section, sectionIndex) => (
              <div key={sectionIndex} className='mt-[10px] w-full relative'>
                {sectionIndex > 0 && <button onClick={()=>{removeSection(sectionIndex)}} className='z-50 absolute right-0 -top-[5px] w-[30px] h-[30px] text-gray-500 hover:text-red-600 flex items-center justify-center bg-transparent hover:bg-gray-200 transition duration-200'>
                  <TrashIcon className={`w-5 h-5`} />                
                </button>}

                <div className='mb-4 mt-2 w-full'>
                  <TextField
                    inputLabel={`Section ${sectionIndex + 1}`}
                    fieldId="question-name" 
                    inputType="text" 
                    preloadValue={section.title || ''}
                    hasError={validationErrors[`section-${sectionIndex}-title`] } 
                    height={`120px`}
                    inputPlaceholder={`Type the option here`}
                    returnFieldValue={(value)=>{updateSection(sectionIndex, 'title', value)}}
                  />
                </div>

                <div className='mb-4 mt-2 w-full'>
                  <TextareaField
                    inputLabel="Description" 
                    fieldId={`section-${sectionIndex}-description`}
                    inputType="text" 
                    preloadValue={section.description || ''}
                    hasError={false} 
                    height={`120px`}
                    returnFieldValue={(value)=>{updateSection(sectionIndex, 'description', value)}}
                  />
                </div>

                <p className='text-sm mt-[20px]'>Section Questions</p>
                <p className='text-xs text-gray-500'>Please populate some questions for this form section</p>

                <div className={`p-5 border rounded-[8px] mt-4 max-w-[100%] ${validationErrors[`section-${sectionIndex}-questions`] ? 'border-red-500' : 'border-gray-200'}`}>
                  {section.questions && section.questions.length > 0 && <DragDropList listItems={section.questions} returnSorting={(list)=>{}} ItemTemplate={QuestionDraggable} />}
                  <button onClick={()=>{openSectionQuestionsAddition(sectionIndex)}} className='rounded-[8px] mt-[12px] p-3 border border-dashed border-gray-300 w-full text-xs text-gray-400 text-center hover:border-gray-600 hover:text-gray-600 transition duration-200'>
                    <span className='flex items-center justify-center gap-x-[5px] uppercase tracking-[0.2em] '>
                      <PlusIcon className={`w-5 h-5`} />
                      add new questions
                    </span>
                    click to add a new questions
                  </button>
                </div>

              </div>
            ))}
            <div className='flex flex-row-reverse mt-[20px]'>
              <button onClick={()=>{addSection()}} className='flex items-center justify-center font-[500] text-xs gap-x-[5px] border border-verovian-purple bg-verovian-light-purple bg-opacity-50 text-verovian-purple rounded p-[10px]'>
                <PlusIcon className={`w-5 h-5`} />
                Add another section
              </button>
            </div>
          </div>

        </div>
        <div className='flex flex-row-reverse mt-[10px]'>
          <div className='w-max'>
            <FormButton buttonLabel={`Save Updates`} processing={reviewsSelector.creatingReviewQuestion} buttonAction={()=>{pushForm()}} />
          </div>
        </div>

      </div>}
    </ReviewsManagementLayout>

    <ModalLayout
      isOpen={addingQuestions} 
      closeModal={()=>{setAddingQuestions(false)}} 
      actionFunction={()=>{}} 
      actionFunctionLabel='' 
      dialogTitle='Add Questions'
      dialogIntro={`Select the questions required for this section below`}
      maxWidthClass='max-w-xl'
    >
      <QuestionsSelector  
        questions={reviewsSelector?.reviewQuestions?.questions}
        selectedQuestions={formPayload.sections[activeSectionIndex]?.questions}
        saveNewList={(list)=>{updateQuestionsList(activeSectionIndex, list)}}
        closeModal={()=>{setAddingQuestions(false)}}
      />
    </ModalLayout>
  </>
  )
}

export default EditReviewForm