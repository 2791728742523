import React, { useEffect, useState } from 'react'
import TextField from '../../elements/form/TextField'
import TextareaField from '../../elements/form/TextareaField'
// import AutocompleteSelect from '../../elements/form/AutocompleteSelect'
import FormButton from '../../elements/form/FormButton'
import RadioGroup from '../../elements/form/RadioGroup'
import SelectField from '../../elements/form/SelectField'
import { useDispatch, useSelector } from 'react-redux'
import { ERROR } from '../../../store/types'
import DateField from '../../elements/form/DateField'
import { fetchEmployees } from '../../../store/actions/employeeActions'
import { createTask } from '../../../store/actions/tasksActions'

const NewTask = ({taskStatus, parentTask}) => {
    const dispatch = useDispatch()
    const employeesSelector = useSelector(state => state.employees)
    const tasksSelector = useSelector(state => state.tasks)
    useEffect(() => {
        dispatch(fetchEmployees())
        return () => {
            
        };
    }, [dispatch]);

    const [taskPayload, setTaskPayload] = useState({});
    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        let errors = {}
        if(!taskPayload.title || taskPayload.title === ''){
            errors.title = true
        }
        setValidationErrors(errors)
        return errors
    }

    const pushTask = () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }

        const payload = taskPayload
        if(taskStatus && taskStatus !== ''){
            payload.status = taskStatus
        }
        if(parentTask && parentTask !== ''){
            payload.parent = parentTask
        }
        dispatch(createTask(payload))
    }
    return (
        <div className='w-full p-[20px'>
            <div className='my-1 w-full'>
                <TextField
                    inputLabel="Title" 
                    fieldId="task" 
                    inputType="text" 
                    preloadValue={''}
                    hasError={validationErrors && validationErrors.title} 
                    returnFieldValue={(value)=>{setTaskPayload({...taskPayload, ...{title: value}})}}
                />
            </div>
            <div className='my-1 w-full'>
                <TextareaField
                    inputLabel="Description" 
                    fieldId="task-description" 
                    inputType="text" 
                    preloadValue={''}
                    hasError={false} 
                    returnFieldValue={(value)=>{setTaskPayload({...taskPayload, ...{description: value}})}}
                />
            </div>
            
            <div className='mt-3 mb-1'>
                <label 
                className={`cursor-text bg-transparent text-sm z-10 font-[550] transition duration-200  
                text-gray-800 `}>
                    Assign this task
                </label>
                <div className='my-1 w-full'>
                {employeesSelector?.employees.length > 0 ?
                    <SelectField
                        selectOptions={employeesSelector?.employees}
                        inputLabel="Select Assignee"
                        titleField="fullName"
                        displayImage={false}
                        imageField=""
                        preSelected=''
                        fieldId="task-assignee"
                        hasError={false}
                        // return id of accounts of the selected option
                        returnFieldValue={(value) => {setTaskPayload({...taskPayload, ...{assignee: value._id}})}}
                    />
                    :
                    <div className='rounded p-[15px] bg-gray-100 text-center'>
                        <p className='text-gray-600 text-xs'>No employees are fully onboarded yet. You will be able to assign this task to an employee when you have onnboarded employees.</p>
                    </div>
                }
                </div>
            </div>

            <div className='my-1 w-full'>
                <DateField
                    inputLabel="Due date" 
                    fieldId="task-due-date" 
                    inputType="date" 
                    preloadValue={''}
                    hasError={false} 
                    returnFieldValue={(value)=>{setTaskPayload({...taskPayload, ...{dueDate: value}})}}
                />
            </div>

            <div className='my-1 w-full'>
                <RadioGroup
                    inputLabel="Task priority" 
                    items={[
                        {
                            label: 'Urgent',
                            value: 'urgent'
                        },
                        {
                            label: 'High',
                            value: 'high'
                        },
                        {
                            label: 'Medium',
                            value: 'medium'
                        },
                        {
                            label: 'Low',
                            value: 'low'
                        },
                    ]}
                    preloadValue={''}
                    hasError={false} 
                    inline={true}
                    returnSelected={(value)=>{setTaskPayload({...taskPayload, ...{priority: value.value}})}}
                />
            </div>

            <div className='mt-3'>
                <FormButton buttonLabel="Create task" buttonAction={()=>{pushTask()}} processing={tasksSelector.creatingTask} />
            </div>
        </div>
    )
}

export default NewTask