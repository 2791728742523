import React from 'react'
import Chart from "react-apexcharts";
import PropTypes from 'prop-types'; 

const Donut = ({showLegend, showDataLabels, size, series, colours, labels }) => {
    const donutState = {
        series: series,
        options: {
            dataLabels: {
                enabled: showDataLabels
            },
            colors: colours,
            labels: labels,
            chart: {
                type: 'donut'
            },
            plotOptions: {
                pie: {
                  donut: {
                    size: size
                  }
                }
              },
            legend: {
                show: showLegend,
                position: 'bottom'
            },
            responsive: [{
                breakpoint: 480,
                options: {
                chart: {
                    width: '100%'
                },
                legend: {
                    show: false,
                    position: 'bottom'
                }
            }
          }],
        },
    };

    return (
        <div id="chart">
            <Chart options={donutState.options} series={donutState.series} type="donut" />
        </div>
    )
}

Donut.propTypes = {
    showLegend: PropTypes.bool.isRequired,
    showDataLabels: PropTypes.bool.isRequired,
    size: PropTypes.string.isRequired,
    series: PropTypes.array.isRequired,
    colours: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired
    // element: PropTypes.arrayOf(PropTypes.element).isRequired
  };

export default Donut
