import React, { useState } from 'react'
import TextField from '../../elements/form/TextField'
import RadioGroup from '../../elements/form/RadioGroup'
import TextareaField from '../../elements/form/TextareaField'
import SelectField from '../../elements/form/SelectField'
import Checkbox from '../../elements/form/Checkbox'

const ReviewQuestion = ({question}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const toggleOption = (option) => {
        if(selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter(opt => opt !== option));
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
    }
    return (
        <div className='w-full p-[20px]'>
            <p className='text-sm mb-[10px] text-black'>{question.question}</p>
            {question.instructions && question.instructions !== '' && <p className='text-xs text-gray-500 mb-[20px]'>{question.instructions}</p>}
            {question.fieldType === 'text' && 
                <TextField
                    inputLabel={`Response`}
                    fieldId={`question-${question.questionCode}-response`} 
                    inputType="text" 
                    preloadValue={''}
                    hasError={false} 
                    inputPlaceholder={`Type response here`}
                    returnFieldValue={(value)=>{}}
                />
            }
            {question.fieldType === 'textarea' && 
                <TextareaField
                    inputLabel={`Response`}
                    fieldId={`question-${question.questionCode}-response`} 
                    inputType="text" 
                    preloadValue={''}
                    hasError={false} 
                    inputPlaceholder={`Type response here`}
                    returnFieldValue={(value)=>{}}
                />
            }
            {question.fieldType === 'radio' && 
                <RadioGroup
                    inputLabel="Response"
                    items={question.options.map(option => {return {label: option}})} 
                    inline={false}
                    hasError={false} 
                    returnSelected={(value)=>{}}
                />
            }
            {question.fieldType === 'select' && 
                <SelectField
                    selectOptions={question.options.map(option => {return {label: option}})}
                    inputLabel="Response"
                    titleField="label"
                    displayImage={false}
                    imageField=""
                    preSelected={''}
                    preSelectedLabel={`label`}
                    fieldId={`response-${question.questionCode}`}
                    hasError={false}
                    returnFieldValue={(value)=>{}}
                />
            }
            {question.fieldType === 'checkbox' && 
                question.options.map((option, optionIndex) => (
                <div key={optionIndex} className={`flex items-start gap-x-2 mb-4 cursor-pointer`} onClick={(()=>{toggleOption(option)})}>
                    <div className='w-[25px] pt-[3x]'>
                    <Checkbox 
                        isChecked={selectedOptions.includes(option)}
                        checkboxToggleFunction={()=>{}}
                        checkboxLabel=''
                    />
                    </div>
                    <div className='w-full'>
                        <p className='text-sm text-gray-700 font-medium'>{option}</p>
                    </div>
                </div>
                ))
            }

        </div>
    )
}

export default ReviewQuestion