import { CREATE_TASK, CREATING_TASK, DELETE_TASK, DELETING_TASK, GET_TASKS, GETTING_TASKS, TASKS_ERROR, UPDATE_TASK, UPDATING_TASK } from "../types"

const initialState = {
    tasks: [],
    loadingTasks: true,
    tasksError: null,
    creatingTask: false,
    createdTask: null,
    updatingTask: false,
    updatedTask: null,
    deletingTask: false,
    deletedTask: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_TASKS:
        return {
            ...state,
            loadingTasks:action.payload,
            // fetchingMembers:false
        }
        case GET_TASKS:
        return{
            ...state,
            loadingTasks:false,
            tasksError: false,
            tasks: action.payload,
        }
        case UPDATING_TASK:
        return {
            ...state,
            updatingTask: action.payload,
            // fetchingMembers:false
        }
        case UPDATE_TASK:
        return{
            ...state,
            updatingTask:false,
            tasksError: false,
            updatedTask: action.payload,
        }
        case DELETING_TASK:
        return {
            ...state,
            deletingTask: action.payload,
            // fetchingMembers:false
        }
        case DELETE_TASK:
        return{
            ...state,
            deletingTask:false,
            tasksError: false,
            deletedTask: action.payload,
        }
        case CREATING_TASK:
        return {
            ...state,
            creatingTask: action.payload,
        }
        case CREATE_TASK:
        return{
            ...state,
            creatingTask:false,
            taskError: false,
            createdTask: action.payload,
        }
        case TASKS_ERROR:
        return{
            ...state,
            loadingTasks:false,
            creatingTask:false,
            tasksError: action.payload 
        }
        default: return state
    }

}